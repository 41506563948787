h2 {
  text-align: left;
  color: #d54101;
  font-size: 18px;
}
.blood-pressure-table {
  width: 100%;
  max-width: 600px;
  border-collapse: collapse;
  margin-bottom: 10px;
}

.blood-pressure-table th {
  background-color: #f8b84e;
  color: #000;
  padding: 10px;
  border: 1px solid #ddd;
}

.blood-pressure-table td {
  padding: 10px;
  text-align: center;
  border: 1px solid #ddd;
}

.blood-pressure-table .highlight {
  background-color: #f0e68c;
  font-weight: bold;
}

.note {
  font-style: italic;
  color: #555;
}
