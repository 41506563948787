.App {
  text-align: center;
}

.All-center {
  display: flex;
  justify-content: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Reset styling */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

/* Sidebar styling */
.sidebar {
  width: 300px;
  height: 100%;
  background-color: #f8f9fa;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.2);
  padding: 20px;
  position: fixed;
  margin-top: 71px;
}

.Top-lep00 {
  top: 0;
  left: 0;
}

.search-input {
  width: 94%;
  padding: 8px;
  font-size: 16px;
}

/* Menu items */
.menu-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.menu-item,
.child-item {
  margin: 8px 0;
  font-weight: normal;
  cursor: pointer;
}

/* Content sections */
.content {
  padding-left: 345px;
  width: 100%;
  margin-right: 10px;
  box-sizing: border-box;
}

.section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
}

.container {
  margin: 0 auto;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  width: 100%;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  padding: 10px 0; /* Add some padding if needed */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional: adds a shadow for a floating effect */
  display: flow-root;
  align-content: center;
  background-color: #f8f9fa;
}

.lever1 {
  text-align: left;
  color: #d54101;
  font-weight: bold;
  font-size: 18px;
}

.lever2 {
  text-align: left;
}

.scroll-to-top {
  position: fixed;
  bottom: 60px;
  z-index: 1000;
  background-color: white;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 30px;
  width: 40px;
  height: 40px;
  border: 1px solid rgb(0, 0, 0);
  padding-left: 5.5px;
}

.scroll-to-top:hover {
  background-color: white;
}
