h2 {
  text-align: left;
  color: #d54101;
  font-size: 18px;
}

h1 {
  text-align: left;
  color: #3498db;
  font-size: 16px;
}

table {
  width: 100%;
  border-collapse: collapse;
}
th,
td {
  border: 1px solid #000;
  padding: 8px;
  text-align: left;
}
th {
  background-color: #4caf50;
  color: white;
}
tr:nth-child(even) {
  background-color: #f2f2f2;
}
.c3header {
  background-color: #0073e6;
  color: #fff;
  font-weight: bold;
}
