h2 {
  text-align: left;
  color: #d54101;
  font-size: 18px;
}

.number {
  width: 40px;
  height: 40px;
  color: white;
  font-size: 24px;
  font-weight: bold;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}
