h2 {
  text-align: left;
  color: #d54101;
  font-size: 18px;
}

h1 {
  text-align: left;
  color: #3498db;
  font-size: 16px;
}

.formula {
  font-size: 18px;
  margin: 20px 0;
  text-align: center;
}
.details {
  margin-left: 20px;
}
.formula span {
  font-weight: bold;
  font-size: 20px;
}
