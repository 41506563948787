h2 {
  text-align: left;
  color: #d54101;
  font-size: 18px;
}

.pulse-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.pulse-table thead th {
  background-color: #3498db;
  color: white;
  padding: 10px;
  font-weight: bold;
}

.pulse-table tbody td,
.pulse-table thead th {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
}

.pulse-table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.pulse-table tbody tr:hover {
  background-color: #eaeaea;
}

.card {
  padding: 20px;
  border: 1px solid #a5c88d;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.card h2 {
  text-align: center;
  color: #1e73be;
  margin-bottom: 15px;
}

.card ul {
  list-style-type: disc;
  padding-left: 20px;
}

.card li {
  margin-bottom: 10px;
  line-height: 1.5;
  text-align: justify;
}
